import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import Link from '@atoms/Link/Link';
import useResponsiveCMSMedia from '@hooks/useResponsiveCMSMedia';
import Config from '@config';
import useTranslation from 'next-translate/useTranslation';
import { cmsAlignToFlexAlign, cmsAlignToTextAlign } from '@helpers/cmsComponentHelpers';
import {
  StyledSection,
  StyledDiv,
  StyledText,
  StyledHeading,
  StyledButton,
} from './HardCodedHorizontalBannerComponent.styles';

// TODO: Create a new component in the future this is only a modified copy of HorizontalBannerComponent to fix https://axfood.atlassian.net/browse/B2C-15435
const HorizontalBannerComponent = () => {
  const { t } = useTranslation('startPage');
  const text = (
    <>
      {t('startPage:businessIdea->firstLine')} <br />
      {t('startPage:businessIdea->secondLine')}&nbsp;{t('startPage:businessIdea->secondLineEnd')}
    </>
  );
  const horizontalBanner: any = {
    buttonEnabled: 'false',
    textAlign: 'center',
    title: text,
    titleBanner: 'false',
    titleMobile: text,
    titleTablet: text,
    titleTextColor: 'white',
    urlLinkInNewWindow: 'false',
  };
  const router = useRouter();
  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });

  const { title } = useResponsiveCMSMedia(horizontalBanner);
  const alignItems = cmsAlignToFlexAlign(horizontalBanner?.textAlign);
  const textAlign = cmsAlignToTextAlign(horizontalBanner?.textAlign);

  const isTitleBanner = horizontalBanner?.titleBanner === 'true';
  const isButtonEnabled = horizontalBanner?.buttonEnabled === 'true';

  const button = (
    <StyledButton
      background={horizontalBanner?.buttonColor}
      color={horizontalBanner?.buttonTextColor}
      title={horizontalBanner?.buttonText}
    >
      {horizontalBanner?.buttonText}
    </StyledButton>
  );

  const wrapWithLink = (content: ReactNode) => {
    return (
      <Link
        internal
        href={{ pathname: horizontalBanner?.urlLink, query: { ...router.query, login: true } }}
        asProp={horizontalBanner?.urlLink}
        target={horizontalBanner?.urlLinkInNewWindow === 'true' ? '_blank' : ''}
      >
        {content}
      </Link>
    );
  };

  const banner = (
    <StyledSection fixed={isTitleBanner} clickable={!isButtonEnabled && !!horizontalBanner?.urlLink}>
      <StyledDiv
        style={{
          textAlign,
          alignItems,
        }}
      >
        {horizontalBanner?.title && (
          <StyledHeading color={horizontalBanner?.titleTextColor as HeadingColors} fixed={isTitleBanner} variant="h2">
            {title}
          </StyledHeading>
        )}
        {horizontalBanner?.preamble && !isMobile && (
          <StyledText style={{ color: horizontalBanner?.preambleTextColor }} type="intro">
            {horizontalBanner?.preamble}
          </StyledText>
        )}
        {isButtonEnabled && !isTitleBanner && (horizontalBanner?.urlLink ? wrapWithLink(button) : button)}
      </StyledDiv>
    </StyledSection>
  );

  return !isButtonEnabled && horizontalBanner?.urlLink ? wrapWithLink(banner) : banner;
};

export default HorizontalBannerComponent;
